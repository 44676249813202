import { Button, Container } from '@mui/material'
import React, { useCallback, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../form/Header'
import AppForm from '../form/AppForm'
import {
    addMoreNewDefectiveItem,
    getSearchItems,
} from '../../store/actions/defectiveItemAction'
import ItemSelect from '../form/ItemSelect'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import AppSelectNameId from '../form/AppSelectNameId'
import { AppSelectId } from '../form/AppSelect'

const defectiveTypeList = [
    { id: 2, name: 'Decrease' },
    { id: 1, name: 'Increase' },
]

const DefectiveItemList = ({ defectiveItem, handleDeleteItem }) => (
    <TableRow>
        <TableCell>{defectiveItem.itemSKU}</TableCell>
        <TableCell>
            {defectiveItem.defectiveType === 1 ? '+' : '-'}
            {defectiveItem.quantity}
        </TableCell>
        <TableCell>{defectiveItem.note}</TableCell>
        <TableCell style={{ width: '50px' }}>
            <Button
                onClick={() => handleDeleteItem(defectiveItem.id)}
                size="small"
                color="error"
                variant="outlined"
            >
                Delete
            </Button>
        </TableCell>
    </TableRow>
)

const DefectiveItemForm = ({ setOpen }) => {
    const dispatch = useDispatch()
    const { searchItems, suppliers } = useSelector(
        (state) => state.defectiveItem
    )

    const [itemId, setItemId] = useState(null)
    const [itemStock, setItemStock] = useState(0)
    const [itemOption, setItemOption] = useState(null)
    const [defectiveItems, setDefectiveItems] = useState([])

    const validationSchema = Yup.object().shape({
        quantity: Yup.number()
            .required()
            .label('Quantity')
            .test('must less stock', 'quantity over stock', function (value) {
                const { defectiveType } = this.parent
                return defectiveType !== 2 || value <= itemStock
            }),
        supplierId: Yup.string().required().label('Supplier'),
    })

    const handleSearchItemChange = (option) => {
        if (option) {
            const [, stock] = option.label.split('|')
            setItemId(option.value)
            setItemStock(stock)
            setItemOption(option)
        }
    }

    const handleInputChange = (inputValue) => {
        const values = { search: inputValue }
        dispatch(getSearchItems(values))
    }

    const handleSubmite = useCallback(() => {
        dispatch(addMoreNewDefectiveItem({ defectiveItems }))
        setOpen(false)
    }, [dispatch, defectiveItems, setOpen])

    const handleDeleteItem = useCallback((id) => {
        setDefectiveItems((pre) => pre.filter((item) => item.id !== id))
    }, [])

    return (
        <Container style={{ height: '90vh', overflow: 'scroll' }}>
            <Header title="Add Defective Item" />
            <AppForm
                initialValues={{
                    quantity: '',
                    defectiveType: 2,
                    supplierId: suppliers?.[0]?.id,
                }}
                onSubmit={(values, { resetForm }) => {
                    const [itemSKU] = itemOption.label.split('|')
                    const submitValues = {
                        ...values,
                        itemId,
                        itemSKU,
                        id: defectiveItems.length + 1,
                    }
                    setDefectiveItems((pre) => [...pre, submitValues])
                    setItemOption(null)
                    setItemId(null)
                    setItemStock(0)
                    resetForm()
                }}
                validationSchema={validationSchema}
            >
                <AppSelectNameId
                    name="supplierId"
                    label="Supplier"
                    options={suppliers}
                />
                <ItemSelect
                    name="itemOption"
                    value={itemOption}
                    onChange={handleSearchItemChange}
                    onInputChange={handleInputChange}
                    options={searchItems}
                    placeholder="Defective Item"
                />
                {itemId && (
                    <>
                        <AppSelectId
                            name="defectiveType"
                            label="Type"
                            options={defectiveTypeList}
                        />
                        <AppInput
                            name="quantity"
                            label={`Defective Quantity`}
                            type="number"
                            required={true}
                            inputProps={{ maxLength: 10 }}
                        />
                        <AppInput
                            name="note"
                            label={`Note`}
                            type="text"
                            inputProps={{ maxLength: 200 }}
                        />
                    </>
                )}
                <SubmitButton title="Add" />
            </AppForm>
            {defectiveItems?.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item SKU</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Note</TableCell>
                                        <TableCell
                                            style={{ width: '50px' }}
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {defectiveItems.map((defectiveItem) => (
                                        <DefectiveItemList
                                            defectiveItem={defectiveItem}
                                            key={defectiveItem.id}
                                            handleDeleteItem={handleDeleteItem}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Button
                            color="info"
                            variant="outlined"
                            fullWidth
                            onClick={handleSubmite}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            )}
        </Container>
    )
}

export default DefectiveItemForm
