import React, { useCallback, useState } from 'react'
import BasicModal from '../modal/BasicModal'
import AddNewOrderPayment from './AddNewOrderPayment'
import { Button, Divider, Grid, Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { roleListId, roleType } from '../../config/roleList'
import EditOrderPayment from './EditOrderPayment'
import { checkUserRole } from '../../common/checkUserRole'
import { DELETE_CONFIRM } from '../../constants/form'
import { deleteOrderPayment } from '../../store/actions/orderPaymentAction'

const OrderPaymentList = ({ orderPayment, orderId }) => {
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteOrderPayment(id, orderId))
            }
        },
        [dispatch, orderId]
    )
    return (
        <Paper
            style={{
                marginTop: '20px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '20px',
                    fontWeight: 'bold',
                    width: '100%',
                }}
            >
                <div style={{ width: '50%' }}>{orderPayment?.paymentDate}</div>
                <div
                    style={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <BasicModal open={open} setOpen={setOpen}>
                        <EditOrderPayment
                            orderPayment={orderPayment}
                            setOpen={setOpen}
                            orderId={orderId}
                        />
                    </BasicModal>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.ORDER_PAYMENT,
                                roleType.EDIT
                            )
                                ? setOpen(true)
                                : alert('Not Permit User')
                        }
                    >
                        Edit
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        style={{ marginLeft: '10px' }}
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.ORDER_PAYMENT,
                                roleType.DELETE
                            )
                                ? handleDelete(orderPayment.id)
                                : alert('Not Permit User')
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
            <Divider />
            <div style={{ padding: '20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        Payment Type
                    </Grid>
                    <Grid item xs={10}>
                        {orderPayment?.paymentType}
                    </Grid>
                    <Grid item xs={2}>
                        Amount
                    </Grid>
                    <Grid item xs={10}>
                        ${orderPayment?.amount}
                    </Grid>
                    {orderPayment?.cardNo ? (
                        <>
                            <Grid item xs={2}>
                                Card No
                            </Grid>
                            <Grid item xs={10}>
                                {orderPayment?.cardNo}
                            </Grid>
                        </>
                    ) : null}
                    {orderPayment?.cardType ? (
                        <>
                            <Grid item xs={2}>
                                Card Type
                            </Grid>
                            <Grid item xs={10}>
                                {orderPayment?.cardType}
                            </Grid>
                        </>
                    ) : null}
                    {orderPayment?.refNo ? (
                        <>
                            <Grid item xs={2}>
                                Ref No
                            </Grid>
                            <Grid item xs={10}>
                                {orderPayment?.refNo}
                            </Grid>
                        </>
                    ) : null}
                    {orderPayment?.message ? (
                        <>
                            <Grid item xs={2}>
                                Message
                            </Grid>
                            <Grid item xs={10}>
                                {orderPayment?.message}
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </div>
        </Paper>
    )
}

const PaymentList = ({ order }) => {
    const [open, setOpen] = useState(false)
    return (
        <div style={{ marginTop: '20px' }}>
            <BasicModal open={open} setOpen={setOpen}>
                <AddNewOrderPayment setOpen={setOpen} orderId={order.id} />
            </BasicModal>
            <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => setOpen(true)}
            >
                Add New Payment
            </Button>
            <div>
                {order?.orderpayments?.length > 0
                    ? order?.orderpayments.map((orderPayment) => (
                          <OrderPaymentList
                              key={orderPayment.id}
                              orderPayment={orderPayment}
                              orderId={order.id}
                          />
                      ))
                    : null}
            </div>
        </div>
    )
}

export default PaymentList
