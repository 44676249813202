import React from 'react'
// import { OrderHeader } from '../../pages/order/Order'
import { ReviewOrderStyle } from '../../pages/orderShipment/OrderShipment'
import { formTypeList } from '../../constants/form'
import InvoiceHeader from '../order/InvoiceHeader'
import { printType } from '../../constants/orderType'

const AssemblyToPrint = React.forwardRef(({ order }, ref) => (
    <div className="invoice-container" ref={ref}>
        <div className="order-products-box">
            <InvoiceHeader order={order} type={printType.PACKING_LIST} />
            <div style={{ marginBottom: '30px' }} />
            {/* <OrderHeader order={order} /> */}
            <div className="order-product-items">
                {order?.orderstyles?.map((orderStyle) => (
                    <ReviewOrderStyle
                        key={orderStyle.id}
                        orderStyle={orderStyle}
                        viewType={formTypeList.READ}
                        orderId={order.id}
                    />
                ))}
            </div>
        </div>
    </div>
))

export default AssemblyToPrint
