import { useEffect, useState } from 'react'
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch } from 'react-redux'
import { SEARCH_ORDER_PAYMENTS } from '../../store/types'
import { OrderStatusList, orderTypeList } from '../../constants/orderType'

const SearchOrderPayment = ({
    displayKeyword,
    displayFromDate,
    displayToDate,
    searchStatus,
}) => {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState('')
    const [searchFromDate, setSearchFromDate] = useState('')
    const [searchToDate, setSearchToDate] = useState('')
    const [status, setStatus] = useState('null')

    useEffect(() => {
        setKeyword(displayKeyword ?? '')
        setSearchFromDate(displayFromDate ?? '')
        setSearchToDate(displayToDate ?? '')
        setStatus(searchStatus ?? 'null')
    }, [displayKeyword, displayFromDate, displayToDate])
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_ORDER_PAYMENTS,
            payload: {
                keyword,
                searchFromDate,
                searchToDate,
                status,
            },
        })
    }
    return (
        <div className="search-container">
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container justify="center" alignItems="center" gap={2}>
                    <Grid item xs={3}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            // size="small"
                            id="keyword"
                            label="Search..."
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={['DatePicker', 'DatePicker']}
                            >
                                <DatePicker
                                    label="From date"
                                    value={searchFromDate}
                                    onChange={(newValue) =>
                                        setSearchFromDate(newValue)
                                    }
                                />
                                <DatePicker
                                    label="To date"
                                    value={searchToDate}
                                    onChange={(newValue) =>
                                        setSearchToDate(newValue)
                                    }
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            labelId={`select-label-status`}
                            fullWidth
                            name="status"
                            id="status"
                            // size="small"
                            value={status}
                            // variant="standard"
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <MenuItem value="null">--All--</MenuItem>
                            {Object.values(OrderStatusList)?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {orderTypeList[option]}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default SearchOrderPayment
